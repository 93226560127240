@use 'src/styles/vars';

@keyframes dots {
  0%,
  20% {
    color: transparent;
    text-shadow:
      0.25em 0 0 transparent,
      0.5em 0 0 transparent;
  }
  40% {
    color: vars.$color-black;
    text-shadow:
      0.25em 0 0 transparent,
      0.5em 0 0 transparent;
  }
  60% {
    text-shadow:
      0.25em 0 0 vars.$color-black,
      0.5em 0 0 transparent;
  }
  80%,
  100% {
    text-shadow:
      0.25em 0 0 vars.$color-black,
      0.5em 0 0 vars.$color-black;
  }
}
