@use 'src/styles/vars';

$fc-daygrid-event-dot-width: 8px;

.fc-daygrid-day-events:after,
.fc-daygrid-day-events:before,
.fc-daygrid-day-frame:after,
.fc-daygrid-day-frame:before,
.fc-daygrid-event-harness:after,
.fc-daygrid-event-harness:before {
  clear: both;
  content: '\ ';
  display: table;
}

.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: vars.$color-blue-100 !important;
}
.fc .fc-daygrid-day-frame {
  min-height: 100%;
  position: relative;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.3;
}
.fc .fc-daygrid-day-number {
  padding: 4px;
  position: relative;
  z-index: 4;
}
.fc .fc-daygrid-month-start {
  font-size: 1rem;
  font-weight: 700;
}
.fc .fc-daygrid-day-events {
  margin-top: 1px;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  left: 0;
  position: absolute;
  right: 0;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 2em;
  position: relative;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 1em;
}
.fc .fc-daygrid-event-harness {
  position: relative;
}
.fc .fc-daygrid-event-harness-abs {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fc .fc-daygrid-bg-harness {
  bottom: 0;
  position: absolute;
  top: 0;
}
.fc .fc-daygrid-day-bg .fc-non-business {
  z-index: 1;
}
.fc .fc-daygrid-day-bg .fc-bg-event {
  z-index: 2;
}
.fc .fc-daygrid-day-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-daygrid-event {
  margin-top: 1px;
  z-index: 6;
}
.fc .fc-daygrid-event.fc-event-mirror {
  z-index: 7;
}
.fc .fc-daygrid-day-bottom {
  font-size: 0.875rem;
  margin: 0 2px;
}
.fc .fc-daygrid-day-bottom:after,
.fc .fc-daygrid-day-bottom:before {
  clear: both;
  content: '\ ';
  display: table;
}
.fc .fc-daygrid-more-link {
  border-radius: 0.25rem;
  cursor: pointer;
  line-height: 1;
  margin-top: 1px;
  max-width: 100%;
  overflow: hidden;
  padding: 2px;
  position: relative;
  white-space: nowrap;
  z-index: 4;
}
.fc .fc-daygrid-more-link:hover {
  background-color: vars.$color-gray-100;
}
.fc .fc-daygrid-week-number {
  background-color: vars.$color-gray-400;
  color: vars.$color-gray-600;
  min-width: 1.5em;
  padding: 2px;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 5;
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 2px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px;
}
.fc-direction-ltr .fc-daygrid-more-link {
  float: left;
}
.fc-direction-ltr .fc-daygrid-week-number {
  border-radius: 0 0 3px 0;
  left: 0;
}
.fc-direction-rtl .fc-daygrid-more-link {
  float: right;
}
.fc-direction-rtl .fc-daygrid-week-number {
  border-radius: 0 0 0 3px;
  right: 0;
}
.fc-liquid-hack .fc-daygrid-day-frame {
  position: static;
}
.fc-daygrid-event {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  position: relative;
  white-space: nowrap;
}
.fc-daygrid-block-event .fc-event-time {
  font-weight: 700;
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 1px;
}
.fc-daygrid-dot-event {
  align-items: center;
  display: flex;
  padding: 2px 0;
}
.fc-daygrid-dot-event .fc-event-title {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 700;
  min-width: 0;
  overflow: hidden;
}
.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: vars.$color-gray-100;
}
.fc-daygrid-dot-event.fc-event-selected:before {
  bottom: -10px;
  top: -10px;
}
.fc-daygrid-event-dot {
  border: calc(var($fc-daygrid-event-dot-width) / 2) solid vars.$color-blue-primary;
  border-radius: calc(var($fc-daygrid-event-dot-width) / 2);
  box-sizing: content-box;
  height: 0;
  margin: 0 4px;
  width: 0;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
}
.fc-direction-rtl .fc-daygrid-event .fc-event-time {
  margin-left: 3px;
}
