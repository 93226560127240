@use 'core';

@mixin checkbox-theme($theme) {
  .mat-mdc-checkbox {
    &.mat-primary {
      @include _checkbox-color($theme, primary);
    }
    &.mat-accent {
      @include _checkbox-color($theme, accent);
    }
    &.mat-warn {
      @include _checkbox-color($theme, warn);
    }
  }
}

@mixin _checkbox-color($theme, $palette) {
  --mdc-checkbox-selected-checkmark-color: #{core.get-theme-color($theme, $palette, default-contrast)};
}
