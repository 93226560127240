:root {
  @media screen and (max-width: vars.$sm) {
    @include mat.tabs-density(-2);
    .mat-mdc-tab,
    .mat-mdc-tab-link {
      padding: 0 2px;
      font-size: 0.625rem;
    }
  }
}

.fill-tabs-body {
  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }
}

.mat-mdc-tab-group {
  border-radius: 0.5rem;
  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }
}

.mdc-tab {
  letter-spacing: normal !important;
}

.mat-mdc-tab-link {
  &.mat-white {
    mat-icon {
      color: vars.$color-white;
    }

    .mdc-tab-indicator__content--underline {
      border-color: vars.$color-white !important;
    }
  }
}

.main-footer {
  .mat-mdc-tab-link-container {
    overflow: visible;
  }

  .mat-mdc-tab-link {
    opacity: 1;
    overflow: visible;
    flex-grow: 1;
    min-width: unset;
    padding: 0;
  }
}
