@use 'src/styles/vars';

.main-backdrop {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 150px;

  &.default {
    background-color: rgba(vars.$color-white, 0.5);

    .lds-ring div {
      border-color: vars.$color-blue-primary transparent transparent transparent;
    }

    .eye {
      fill: vars.$color-blue-primary;
    }
  }

  &.inverted {
    .lds-ring div {
      border-color: vars.$color-white transparent transparent transparent;
    }

    .eye {
      fill: vars.$color-white;
    }
  }

  .loader {
    width: 100px;
    height: 100px;
    box-shadow: vars.$shadow-standard;
    border-radius: 50%;
    position: relative;
  }

  svg {
    width: 49px;
    height: auto;
    left: 26px;
    top: 32px;
    position: absolute;
  }

  .eye {
    transform-origin: 50%;
    animation: blink 6s infinite;
  }

  @keyframes blink {
    0%,
    100% {
      transform: scale(1, 0.05);
    }
    5%,
    95% {
      transform: scale(1, 1);
    }
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 0px;
    border: 4px solid vars.$color-white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: vars.$color-blue-primary transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
