@use 'src/styles/vars';

.mat-mdc-progress-bar {
  &.mat-info {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-blue-300};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-blue-primary, 25%)};
  }

  &.mat-success {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-green-500};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-green-500, 25%)};
  }

  &.mat-warning {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-yellow-500};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-yellow-500, 25%)};
  }

  &.mat-danger {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-red-500};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-red-500, 25%)};
  }
}
