.ql-container {
  height: auto;
  video {
    position: relative;
    display: block;
    width: auto;
    max-height: 200px;
  }
}

quill-view-html {
  .ql-editor {
    padding: 0;
  }
}

quill-view {
  .ql-editor {
    padding: 10px;
  }
}

.ql-editor {
  -webkit-user-select: text;
  user-select: text;
  overflow: hidden;
}

.ql-toolbar {
  padding-top: 12px !important;
}

.label-quill {
  color: vars.$color-gray-600;
  font-size: 0.75rem;
  font-weight: normal;
  position: absolute;
  background-color: vars.$color-white;
  top: -8px;
  left: 12px;
  padding: 0px 4px;
  z-index: 1;
}

app-panel-tarjetas-evento {
  .ql-editor {
    max-height: 55px !important;
    overflow: hidden !important;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

quill-editor {
  margin-bottom: 1rem;
  width: 100%;

  .ql-tooltip {
    z-index: 2;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &.ng-invalid.ng-touched {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: vars.$color-orange-500;
      border-width: 2px;
    }
  }

  .ql-medium,
  .ql-editor {
    min-height: 6rem !important;
    height: auto;
  }
}

app-modal-evento-detalle {
  app-panel-tarjetas-evento {
    .ql-editor {
      max-height: 100% !important;
      -webkit-line-clamp: initial;
    }
  }
}

.ql-emoji-side {
  svg {
    height: 1.2rem;
  }
}
