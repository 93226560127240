@use 'src/styles/vars';

.resource-grid {
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0px;
  }
  .fc-theme-standard .fc-scrollgrid {
    border: 0 !important;
  }
  thead {
    th {
      background-color: vars.$color-gray-100;
      color: vars.$color-gray-600;
      font-weight: normal;
    }
    .fc-datagrid-cell-frame {
      background-color: vars.$color-gray-100;
      color: vars.$color-gray-600;
      font-weight: normal;
      height: 62px !important;
    }
    .fc-timeline-slot-frame {
      height: 62px !important;
      justify-content: center !important;
    }
    .fc-scroller {
      overflow: auto !important;
    }
  }

  .fc-timeline-event-workday-remote {
    border-radius: 0.25rem;
    border-color: vars.$color-gray-300 !important;
    background-image: linear-gradient(
      to top,
      vars.$color-violet-300 0px,
      vars.$color-violet-300 12px,
      vars.$color-blue-100 12px,
      vars.$color-blue-100 100%
    );
  }

  .fc-timeline-event-workday-in-office {
    border-radius: 0.25rem;
    border-color: vars.$color-gray-300 !important;
    background-image: linear-gradient(
      to top,
      vars.$color-blue-primary 0px,
      vars.$color-blue-primary 12px,
      vars.$color-blue-100 12px,
      vars.$color-blue-100 100%
    );
  }

  .fc-timeline-event-workday-hybrid {
    border-radius: 0.25rem;
    border-color: vars.$color-gray-300 !important;
    background-image: linear-gradient(
      to top,
      vars.$color-green-500 0px,
      vars.$color-green-500 12px,
      vars.$color-blue-100 12px,
      vars.$color-blue-100 100%
    );
  }

  &.week-view {
    .fc-timeline-events {
      height: 100% !important;
      box-sizing: border-box !important;
    }
    .fc-timeline-event-harness {
      height: 100%;
    }
    .fc-event {
      height: 100%;
      margin: 2px;
    }
    .fc-event-main {
      height: 100%;
    }
  }
}
