@use 'core';

@mixin list-option-theme($theme) {
  .mat-mdc-list-option {
    @include _checkbox-selected-checkmark-color($theme, primary);
  }
}

@mixin _checkbox-selected-checkmark-color($theme, $palette) {
  --mdc-checkbox-selected-checkmark-color: #{core.get-theme-color($theme, $palette, default-contrast)};
}
