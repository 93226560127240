@use 'core';

@mixin datepicker-theme($theme) {
  .mat-datepicker-content {
    &.mat-primary {
      @include _datepicker-color($theme, primary);
    }
    &.mat-accent {
      @include _datepicker-color($theme, accent);
    }
    &.mat-warn {
      @include _datepicker-color($theme, warn);
    }
  }
}

@mixin _datepicker-color($theme, $palette) {
  --mat-datepicker-calendar-date-selected-state-text-color: #{core.get-theme-color($theme, $palette, default-contrast)};
}
