@use '@angular/material' as mat;
@use 'vars';
@import 'animations';
@import 'neo-components';
@import 'components';
@import 'utils';
@import 'theme';
@import 'colors';
@import 'dashboard';
@import 'sidebar';
@import 'login';
@import 'button';
@import 'table';
@import 'cards';
@import 'events';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import 'quill.scss';
@import 'quill-mention.scss';
@import 'fullcalendar-daygrid.scss';
@import 'fullcalendar-core.scss';
@import 'fullcalendar-resourcesgrid.scss';
@import 'fullcalendar-timeline.scss';
@import '_emoji-mart.scss';
@import 'print';
@import 'button-toggle';
@import 'new-material/mat-tab';
@import 'new-material/mat-theme';
@import 'new-material/mat-table';
@import 'new-material/mat-menu';
@import 'new-material/mat-dialog';
@import 'new-material/mat-progress-bar';
@import 'new-material/mat-form-field';
@import 'new-material/mat-radio-button';
@import 'spinner';
@import 'datetimepicker';

body,
html {
  background-color: transparent;
  height: 100%;
}

body {
  background-color: vars.$color-gray-100;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: vars.$color-gray-600;
}

mat-icon {
  font-family: 'Material Symbols Outlined' !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.icon::before {
  display: none;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: vars.$color-gray-600;
  font-weight: 500;
}

.modal {
  overscroll-behavior-y: contain;
}

.lg-logo {
  height: 24px;
}

.perfil-empresa-header {
  border-radius: 1rem;
  height: 125px;
}

.container-icon {
  height: 24px;
  width: 24px;
}

.space-t-b {
  padding: 1rem 0 !important;
}

.empresa-imagen {
  transform: translate(0, -50%);
}

.scroll-tabs {
  .mat-tab-body-wrapper {
    flex-basis: 0 !important;
  }
}

.icon-adjust {
  margin-left: 0.2em;
}

app-detalle-empleado-modal {
  display: flex;
  flex: 1;
  flex-direction: column;
}

app-detalle-empleado-modal {
  cdk-virtual-scroll-viewport {
    min-height: calc(100vh - 300px);

    @media screen and (max-width: vars.$sm) {
      min-height: calc(100vh - 430px);
    }
  }
}

.treeview-container .form-check {
  display: inline !important;
}

.flex-text-truncate {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0;
}

.flex-text-truncate-none-flex-childs {
  flex-grow: 1;
  min-width: 0;
  // El elemento hijo debe contener la clase text-truncate
  .text-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.line-1 {
  line-height: 1rem;
}

.graph-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

small.smaller {
  font-size: 0.625rem;
  line-height: 0.625rem;
}

.select-all {
  height: 38px;
  padding-top: 10px;
}

.right-menu {
  border-radius: 0.25rem;
  display: block;
  opacity: 1;
  z-index: 1000;
}

.right-menu div:hover {
  cursor: pointer;
}
.right-menu-element {
  padding: 10px;
}

.table-fixed-double {
  height: 180px;
  overflow: auto;
}

td.mat-cell:not(.cdk-column-expandedDetail),
td.mat-footer-cell,
th.mat-header-cell {
  padding: 0.4rem !important;
}

.chart-legend .legend-labels {
  background: none !important;
}

.chart-legend .legend-label-text {
  white-space: pre-wrap !important;
}

.legend-label-text {
  font-size: 0.625rem;
}

.reference-label {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
}

.label-text {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label-text-disable {
  color: vars.$color-gray-600;
}

.referencia-circle {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.file-input {
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-content {
    position: absolute;
    text-align: center;

    p {
      color: vars.$color-gray-600;
      margin: 10px;
    }
  }

  &-displayed {
    height: 120px;
  }

  &-hidden {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.dashboard-footer {
  background: vars.$color-white !important;
  background-color: vars.$color-white !important;
  border-top: vars.$border-standard !important;
  line-height: 32px !important;
  width: 100%;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

neo-timepicker {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.2em !important;
  }
}

.border-form {
  border: vars.$border-standard !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.info-font {
  font-size: 0.75rem;
  word-wrap: break-word;
}

.cuestionarios {
  mat-form-field {
    .mat-form-field-infix {
      color: vars.$color-gray-600 !important;
      min-width: 260px !important;
      width: 260px !important;

      @media screen and (max-width: vars.$lg) {
        min-width: 160px !important;
        width: 160px !important;
      }

      @media screen and (max-width: vars.$sm) {
        min-width: 80px !important;
        width: 80px !important;
      }
    }
  }

  .box-table-encabezado {
    margin-right: 8px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .persona-col {
    padding-left: 0.7rem !important;
    @media screen and (max-width: 767px) {
      justify-content: center;
      padding-left: 0.25rem !important;
      text-align: center;
      width: 60px !important;
    }
  }

  .mat-standard-chip::after {
    opacity: 0 !important;
  }

  .mat-select-value {
    color: vars.$color-gray-600 !important;
  }

  .mat-sort-header-container {
    justify-content: center !important;
  }
}

.tab-container {
  padding: 1.5rem;
}

.justify-content-between mat-chip-list {
  min-width: fit-content !important;
}

app-cuestionario-preguntas {
  .dashboard-base-title {
    padding: 0.5rem 1rem 0.5rem 0 !important;
  }

  .rounded-label {
    margin-right: 5px;

    .material-symbols-outlined {
      font-size: 1.25rem !important;
    }
  }
}

.rounded-label {
  align-items: center;
  background-color: vars.$color-blue-primary;
  border-radius: 50%;
  color: vars.$color-white;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  margin-right: 10px;
  padding: 5px;
  position: relative;
  top: 4px;
  width: 20px;

  .material-symbols-outlined {
    font-size: 1.25rem !important;
  }
}

neo-completer-mat {
  position: relative;
  z-index: 400;
  .mat-mdc-text-field-wrapper {
    z-index: 1 !important;
  }
  mat-chip span {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

.icon-sm {
  align-items: center;
  display: flex !important;
  font-size: 1.25rem;
  justify-content: center;
}

app-documentacion-admin-detalle {
  .mat-column-Accion {
    max-width: 100px;
    width: 100px;
  }

  table {
    width: 100%;
  }
}

.detalle-persona-contacto-modal {
  @media screen and (min-width: (vars.$md + 1)) {
    width: 600px;
  }

  @media screen and (max-width: vars.$md) {
    width: 90%;
  }
}

.html-view-container {
  border: vars.$border-standard;
  padding: 5px 10px;
  border-radius: 0.25rem;
  margin: 1em 0;
  background-color: vars.$color-gray-100;
  word-break: break-word;
}

.dot-new-inbox {
  background: vars.$color-blue-primary;
  border-radius: 50%;
  content: '\a';
  display: inline-block;
  height: 10px;
  margin: 5px 8px 0px 0px;
  width: 10px;

  &-hidden {
    visibility: hidden;
  }
}

.bem {
  margin-top: inherit;
}

.color-link {
  color: vars.$color-blue-primary !important;
}

.background-app {
  background-color: vars.$color-blue-primary;
  color: vars.$color-white;
}

.calendar-header {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  width: 100%;

  span {
    flex-grow: 1;
  }
}

.red-dot::after {
  background: vars.$color-red-500;
  border-radius: 50%;
  content: '\A';
  display: inline-block;
  height: 10px;
  margin-left: 8px;
  width: 10px;
}

.green-dot::after {
  background: vars.$color-green-500;
  border-radius: 50%;
  content: '\A';
  display: inline-block;
  height: 10px;
  margin-left: 8px;
  width: 10px;
}

.mat-expansion-panel-header-description {
  flex: 0 0 auto !important;
}

.permissions-tree-invisible {
  display: none;
}

.permissions-tree ul,
.permissions-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.permissions-tree .mat-nested-tree-node div[role='group'] {
  padding-left: 30px;
}

.permissions-tree div[role='group'] > .mat-tree-node {
  padding-left: 30px;
}

.dot-type-feedback {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: vars.$color-gray-100;
  display: inline-block;
}

.calendly-popup {
  background-color: vars.$color-white;
  border-radius: 0.5rem;
}

.boton-round {
  margin-right: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0 !important;
  & .mat-button-toggle-ripple {
    border-radius: 50%;
  }
}

.no-max-width {
  max-width: none !important;
}

.info-snackbar {
  --mdc-snackbar-container-color: #{vars.$color-green-500};
  --mdc-snackbar-supporting-text-color: #{vars.$color-white};
  --mat-snack-bar-button-color: #{vars.$color-white};
  .mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #{vars.$color-white};
  }
}

.error-snackbar {
  --mdc-snackbar-container-color: #{vars.$color-red-500};
  --mdc-snackbar-supporting-text-color: #{vars.$color-white};
  --mat-snack-bar-button-color: #{vars.$color-white};
  .mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #{vars.$color-white};
  }
}

.warning-snackbar {
  --mdc-snackbar-container-color: #{vars.$color-orange-500};
  --mdc-snackbar-supporting-text-color: #{vars.$color-white};
  --mat-snack-bar-button-color: #{vars.$color-white};
  .mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #{vars.$color-white};
  }
}

.strike-out {
  text-decoration: line-through;
  color: vars.$color-gray-600;
}

.partner-title {
  font-weight: 100;
  color: vars.$color-gray-600;
}

.partner-description {
  font-size: 0.875rem;
  color: vars.$color-gray-600;
}

.partner-image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.side-padding {
  padding: 0 15px !important;
}

.title-container {
  text-align: center;
}

.button-align-right {
  margin: 20px 0 0 auto !important;
  display: block !important;
}

.status-published {
  color: vars.$color-green-500;
}

.status-draft {
  color: vars.$color-gray-600;
}

.status-archived {
  color: vars.$color-orange-500;
}

.status-tag {
  color: vars.$color-white;
  padding: 4px 10px;
  border-radius: 0.25rem;
  height: 30px;
  display: flex;
  align-items: center;
}

.status-tag.published {
  background: vars.$color-green-500;
}

.status-tag.draft {
  background: vars.$color-gray-600;
}

.status-tag.archived {
  background: vars.$color-orange-500;
}

.button-margin {
  margin: 5px !important;
}

.mat-column-actions {
  max-width: 80px;
}

.mat-column-email {
  max-width: 170px;
}

.accordion-headers-align .mat-expansion-panel-header-title,
.accordion-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.accordion-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.accordion-headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.candidate-avatar {
  width: 30px;
  height: 30px;
  background: no-repeat center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  box-shadow: vars.$shadow-standard;
  margin-right: 10px;
}

.vertical-super {
  vertical-align: super !important;
  margin-left: 20px;
}

.phases-list {
  width: 100%;
  max-width: 100%;
  border: solid 1px vars.$color-gray-300;
  min-height: 60px;
  display: block;
  background: vars.$color-white;
  border-radius: 0.25rem;
}

.phases-box {
  padding: 20px 10px;
  border-bottom: solid 1px vars.$color-gray-300;
  color: vars.$color-gray-600;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: move;
  background: vars.$color-white;
  font-size: 0.875rem;
}

.phases-box.cdk-drag-disabled {
  background: vars.$color-gray-300;
  cursor: default;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 0.25rem;
  box-shadow: vars.$shadow-standard;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.phases-box:last-child {
  border: none;
}

.phases-list.cdk-drop-list-dragging .phases-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.wrapper {
  background: vars.$color-gray-300;
  flex-grow: 1;
}

.candidate-table {
  width: 100%;
  max-width: 500px;
  margin: 20px auto 0 auto;
}

.card-header-image {
  background-position: center;
  background-size: contain;
}

.card-header-image img {
  object-fit: cover;
  height: auto;
  width: 100%;
}

.candidate-table .ck-editor__editable_inline {
  min-height: 100px;
}

.feedback {
  margin-bottom: 25px;
}

.public-container {
  padding-top: 100px;
  background-color: vars.$color-white;
}

h1.offer-title,
h3.offer-title {
  float: right;
  margin: 0;
  padding-top: 5px;
  font-weight: 100;
}

.icon-margin {
  margin-right: 10px;
}

.contratado {
  color: vars.$color-green-500;
}

.en-proceso {
  color: vars.$color-blue-primary;
}

.rechazado {
  color: vars.$color-orange-500;
}

.type-info {
  font-size: 0.75rem;
  color: vars.$color-gray-600;
  padding: 0 1em;
}

.map-border {
  border: 5px solid vars.$color-gray-300;
  border-radius: 0.25rem;
}

.required-field {
  font-size: 0.75rem;
  margin-top: 0.6666666667em;
  padding: 0 1em;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.align-outline-suffix {
  .mat-form-field-suffix {
    top: 0em !important;
  }
}

.small-icon {
  transform: scale(0.75);
}

.typing::after {
  content: ' .';
  font-size: 2em;
  animation: dots 1s steps(5, end) infinite;
}

.typing {
  position: absolute;
  bottom: 25px;
  left: 16px;
}

.blue-box {
  color: vars.$color-gray-600;
  font-size: 0.875rem;
  background: vars.$color-blue-100;
  min-height: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.small-reaction-modal {
  width: 25rem;
  app-reactions-detail-modal {
    height: 28rem;
  }
  .mdc-tab {
    padding-right: 0;
    padding-left: 0;
  }
}

.full-size-sm-600-lg-modal {
  width: min(calc(100% - 16px), vars.$sm);
}

.full-size-sm-400-lg-modal {
  width: min(calc(100% - 16px), 400px);
}

.full-size-sm-900-lg-modal {
  width: min(calc(100% - 16px), vars.$md) !important;
}

.full-size-sm-1200-lg-modal {
  width: min(calc(100% - 16px), vars.$lg) !important;
}

.full-height-sm-700-lg-modal {
  height: calc(100vh - 16px) !important;

  @media screen and (min-width: (vars.$md)) {
    height: min(calc(100vh - 16px), 700px) !important;
  }

  .mat-mdc-dialog-content {
    max-height: 90vh !important;
  }
}

.beta-feature {
  height: 24px !important;
  background: vars.$color-blue-primary;
  background: linear-gradient(90deg, vars.$color-blue-primary 18%, vars.$color-green-apple-500 100%);
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .mat-mdc-chip-action-label {
    font-size: 0.625rem !important;
  }
}

/*
* Media ********************************
*/
@media screen and (max-width: vars.$md) {
  mat-horizontal-stepper {
    .mat-step-label {
      display: none;
    }
  }
}

@media screen and (min-width: (vars.$sm + 1)) {
  .mat-tab-group {
    border-radius: 0.5rem;
  }
}

@media screen and (min-width: (vars.$md + 1)) {
  .main-layout {
    form {
      // Forms below the main layout need space for the chat widget
      // it does not affects Modals, they are outside the main layout
      // it does not affects Mobile Devices
      padding-bottom: 1.25rem;
    }
  }
}
