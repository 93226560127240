@use 'src/styles/vars';

$fc-more-link-text-color: inherit;

.fc .fc-timeline-body {
  min-height: 100%;
  position: relative;
  z-index: 1;
}
.fc .fc-timeline-slots {
  bottom: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}
.fc .fc-timeline-slots > table {
  height: 100%;
}
.fc .fc-timeline-slot-minor {
  border-style: dotted;
}
.fc .fc-timeline-slot-frame {
  align-items: center;
  display: flex;
  justify-content: center;
}
.fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
  justify-content: flex-start;
}
.fc .fc-timeline-header-row:last-child .fc-timeline-slot-frame {
  overflow: hidden;
}
.fc .fc-timeline-slot-cushion {
  padding: 4px 5px;
  white-space: nowrap;
}
.fc-direction-ltr .fc-timeline-slot {
  border-right: 0 !important;
}
.fc-direction-rtl .fc-timeline-slot {
  border-left: 0 !important;
}
.fc .fc-timeline-now-indicator-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 4;
}
.fc .fc-timeline-now-indicator-arrow,
.fc .fc-timeline-now-indicator-line {
  border-color: vars.$color-blue-primary;
  border-style: solid;
  position: absolute;
  top: 0;
}
.fc .fc-timeline-now-indicator-arrow {
  border-left-color: transparent;
  border-right-color: transparent;
  border-width: 6px 5px 0;
  margin: 0 -6px;
}
.fc .fc-timeline-now-indicator-line {
  border-width: 0 0 0 1px;
  bottom: 0;
  margin: 0 -1px;
}
.fc .fc-timeline-events {
  position: relative;
  width: 0;
  z-index: 3;
}
.fc .fc-timeline-event-harness,
.fc .fc-timeline-more-link {
  position: absolute;
  top: 0;
}
.fc-timeline-event {
  z-index: 1;
}
.fc-timeline-event.fc-event-mirror {
  z-index: 2;
}
.fc-timeline-event {
  align-items: center;
  border-radius: 0;
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 1px;
  padding: 2px 1px;
  position: relative;
}
.fc-timeline-event .fc-event-main {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
.fc-timeline-event .fc-event-time {
  font-weight: 700;
}
.fc-timeline-event .fc-event-time,
.fc-timeline-event .fc-event-title {
  padding: 0 2px;
  white-space: nowrap;
}
.fc-direction-ltr .fc-timeline-event.fc-event-end,
.fc-direction-ltr .fc-timeline-more-link {
  margin-right: 1px;
}
.fc-direction-rtl .fc-timeline-event.fc-event-end,
.fc-direction-rtl .fc-timeline-more-link {
  margin-left: 1px;
}
.fc-timeline-overlap-disabled .fc-timeline-event {
  margin-bottom: 0;
  padding-bottom: 5px;
  padding-top: 5px;
}
.fc-timeline-event:not(.fc-event-end):after,
.fc-timeline-event:not(.fc-event-start):before {
  border-color: transparent vars.$color-black;
  border-style: solid;
  border-width: 5px;
  content: '\ ';
  flex-grow: 0;
  flex-shrink: 0;
  height: 0;
  margin: 0 1px;
  opacity: 0.5;
  width: 0;
}
.fc-direction-ltr .fc-timeline-event:not(.fc-event-start):before,
.fc-direction-rtl .fc-timeline-event:not(.fc-event-end):after {
  border-left: 0;
}
.fc-direction-ltr .fc-timeline-event:not(.fc-event-end):after,
.fc-direction-rtl .fc-timeline-event:not(.fc-event-start):before {
  border-right: 0;
}
.fc-timeline-more-link {
  background: var(--fc-more-link-bg-color);
  color: var($fc-more-link-text-color);
  cursor: pointer;
  font-size: 0.875rem;
  padding: 1px;
}
.fc-timeline-more-link-inner {
  display: inline-block;
  left: 0;
  padding: 2px;
  right: 0;
}
.fc .fc-timeline-bg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 2;
}
.fc .fc-timeline-bg .fc-non-business {
  z-index: 1;
}
.fc .fc-timeline-bg .fc-bg-event {
  z-index: 2;
}
.fc .fc-timeline-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-timeline-bg-harness {
  bottom: 0;
  position: absolute;
  top: 0;
}
