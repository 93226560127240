@use 'src/styles/vars';

.login-container {
  background-color: vars.$color-white;
  height: 100%;

  hr {
    border-top: 2px solid vars.$color-blue-primary;
    width: 25%;
  }
}

.logo-container {
  padding: 2rem 2rem 0;
}

.login-form-container {
  padding: 1rem 2rem !important;
  z-index: 1;
  background-color: vars.$color-gray-100 !important;

  h1 {
    font-size: 1rem;
  }

  button.signin {
    padding: 0 2rem;
  }

  .smaller {
    font-size: 0.625rem;
    text-align: center;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: vars.$color-white;
  }
}

.logo-login {
  height: 40px;
}

.btn-app {
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  background-color: vars.$color-black;
  display: flex;

  img {
    width: 100%;
  }

  &:first-child {
    margin-right: 0.5em;
  }
}

.login-footer {
  padding: 2rem 2rem 0 2rem;
  color: vars.$color-gray-600;

  a {
    color: vars.$color-gray-600 !important;
    margin: 0 1rem;
    font-size: 0.75rem;
  }
}

.grecaptcha-label {
  font-size: 0.625rem;
  color: vars.$color-gray-600;
}

.apple-signin {
  border: vars.$border-standard;
  cursor: pointer;
  border-radius: 0.25rem;
}

@media screen and (max-width: vars.$md) {
  .login-form-container {
    padding: 1rem 0 !important;
    width: 90vw;
  }

  .login-footer,
  .grecaptcha-label {
    display: none;
  }
}
