.mat-datepicker-content,
.time-container {
  box-shadow: vars.$shadow-standard;
  display: block;
  border-radius: 0.25rem;
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);
}

.mat-calendar-body-cell-content {
  color: var(--mat-datepicker-calendar-date-text-color);
  border-color: var(--mat-datepicker-calendar-date-outline-color);
}

.mat-calendar-body-selected {
  background-color: var(--mat-datepicker-calendar-date-selected-state-background-color);
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--mat-datepicker-calendar-date-hover-state-background-color);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--mat-datepicker-calendar-date-disabled-state-text-color);
}
