@use 'core';

@mixin slide-toggle-theme($theme) {
  .mat-mdc-slide-toggle {
    &.mat-primary {
      @include _slide-toggle-color($theme, primary);
    }
    &.mat-accent {
      @include _slide-toggle-color($theme, accent);
    }
    &.mat-warn {
      @include _slide-toggle-color($theme, warn);
    }
  }
}

@mixin _slide-toggle-color($theme, $palette) {
  --mdc-switch-selected-icon-color: #{core.get-theme-color($theme, $palette, default-contrast)};
}

mat-slide-toggle {
  &.wrap-content {
    .mat-slide-toggle-content {
      white-space: pre-wrap !important;
    }
  }
}
