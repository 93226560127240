@use 'vars';

.mdc-dialog__title::before {
  display: none !important;
}

.mat-mdc-dialog-title {
  padding-top: 1rem !important;
  --mdc-dialog-subhead-color: #{lighten(vars.$color-gray-600, 10%)};
}

.mat-mdc-dialog-content {
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-line-height: 1.2rem;
}

.mat-mdc-dialog-panel {
  --mat-dialog-container-small-max-width: 100vw;
}

.dialog-close-icon:hover {
  color: vars.$color-black;
  cursor: pointer;
}

.dialog-close-icon {
  color: vars.$color-gray-600;
}

.gray-dialog {
  .mat-dialog-container {
    background-color: vars.$color-gray-100;
  }
}

// Para utilizar el modal sin márgenes y que quede completamente azul, utilizar la clase no-padding
.no-padding .mat-dialog-container {
  padding: 0 !important;
}

.flex-modal .mat-dialog-container {
  display: flex !important;
  padding: 0 !important;
}

// Agrega scroll al modal si sobrepasa al alto de la pantalla
.mat-dialog-container {
  max-height: 100vh !important;
  position: relative;
}

.mat-mdc-dialog-surface > *:first-child {
  /* Style for the first child */
  height: 100%; /* Example style */
  display: flex;
  flex-direction: column;
}
