$color-gray-100: #f9fafb;
$color-gray-200: #f0f1f2;
$color-gray-300: #e5e7eb;
$color-gray-400: #d1d5db;
$color-gray-500: #9ca3af;
$color-gray-600: #6b7280;
$color-gray-700: #4b5563;
$color-gray-800: #374151;
$color-gray-900: #293041;
$color-gray-1000: #111827;

$color-blue-100: #e8f4fe; // hover: list & tables
$color-blue-200: #cde7fd;
$color-blue-300: #9bcffb;
$color-blue-400: #69b7fa;
$color-blue-primary: #379ff8; // $color-blue-500
$color-blue-600: #0080e0;
$color-blue-700: #0064b8;
$color-blue-800: #004c8f;
$color-blue-900: #00356c;

$color-green-100: #e8fee7;
$color-green-500: #34b16c;

$color-green-apple-500: #8ae566;

$color-violet-300: #ba94d4;
$color-violet-700: #6c4388;

$color-yellow-500: #fbde4d;

$color-red-500: #e63946;

$color-orange-100: #fce3c4;
$color-orange-200: #fad6a7;
$color-orange-300: #f8c37f;
$color-orange-400: #f7b766;
$color-orange-500: #f5a540;
$color-orange-600: #df963a;
$color-orange-700: #ae752d;
$color-orange-800: #875b23;
$color-orange-900: #67451b;

$color-black: #030303;
$color-white: #ffffff;

$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1800px;

$shadow-standard: 10px 11px 20px -5px rgba($color-black, 0.1);
$border-standard: 1px solid $color-gray-300;
