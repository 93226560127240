@use 'src/styles/vars';

$fc-event-resizer-dot-total-width: 8px;
$fc-event-resizer-dot-border-width: 1px;
$fc-bg-event-opacity: 0.3;
$fc-more-link-text-color: inherit;
$fc-event-resizer-thickness: 8px;

table.fc-col-header,
.fc-scroller > div,
.fc-scroller > div > table {
  width: 100% !important;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-unselectable {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.fc {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}
.fc,
.fc *,
.fc :after,
.fc :before {
  box-sizing: border-box;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
}
.fc th {
  text-align: center;
}
.fc td,
.fc th {
  padding: 0;
  vertical-align: top;
}
.fc a[data-navlink] {
  cursor: pointer;
}
.fc a[data-navlink]:hover {
  text-decoration: underline;
}
.fc-direction-ltr {
  direction: ltr;
  text-align: left;
}
.fc-direction-rtl {
  direction: rtl;
  text-align: right;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: vars.$border-standard;
}
.fc-liquid-hack td,
.fc-liquid-hack th {
  position: relative;
}

.fc-icon {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: fcicons !important;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  height: 1em;
  line-height: 1;
  text-align: center;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 1em;
}
.fc-icon-chevron-left:before {
  content: '\e900';
}
.fc-icon-chevron-right:before {
  content: '\e901';
}
.fc-icon-chevrons-left:before {
  content: '\e902';
}
.fc-icon-chevrons-right:before {
  content: '\e903';
}
.fc-icon-minus-square:before {
  content: '\e904';
}
.fc-icon-plus-square:before {
  content: '\e905';
}
.fc-icon-x:before {
  content: '\e906';
}
.fc .fc-button {
  border-radius: 0;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
}
.fc .fc-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.fc .fc-button {
  -webkit-appearance: button;
}
.fc .fc-button:not(:disabled) {
  cursor: pointer;
}
.fc .fc-button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.fc .fc-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25em;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.4em 0.65em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.fc .fc-button:hover {
  text-decoration: none;
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.fc .fc-button:disabled {
  opacity: 0.65;
}
.fc .fc-button-primary {
  background-color: vars.$color-blue-900;
  border-color: vars.$color-blue-900;
  color: vars.$color-white;
}
.fc .fc-button-primary:hover {
  background-color: vars.$color-blue-900;
  border-color: vars.$color-blue-900;
  color: vars.$color-white;
}
.fc .fc-button-primary:disabled {
  background-color: vars.$color-blue-900;
  border-color: vars.$color-blue-900;
  color: vars.$color-white;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: vars.$color-blue-900;
  border-color: vars.$color-blue-900;
  color: vars.$color-white;
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}
.fc .fc-button .fc-icon {
  font-size: 1.5rem;
  vertical-align: middle;
}
.fc .fc-button-group {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.fc .fc-button-group > .fc-button {
  flex: 1 1 auto;
  position: relative;
}
.fc .fc-button-group > .fc-button.fc-button-active,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:hover {
  z-index: 1;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.fc-direction-rtl .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
.fc-direction-rtl .fc-button-group > .fc-button:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.fc .fc-toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
}

@media screen and (max-width: vars.$sm) {
  .fc .fc-toolbar.fc-header-toolbar {
    flex-direction: column !important;
  }

  .fc-toolbar-chunk:has(.fc-toolbar-title) {
    order: -1 !important;
  }

  .fc-toolbar-chunk {
    padding: 0.5rem 0 0.5rem 0 !important;
  }
}

.fc .fc-toolbar.fc-footer-toolbar {
  margin-top: 1.5em;
}
.fc .fc-toolbar-title {
  font-size: clamp(1.1em, 2.5vw, 1.75em) !important;
  margin: 0;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.75em;
}
.fc-direction-rtl .fc-toolbar > * > :not(:first-child) {
  margin-right: 0.75em;
}
.fc-direction-rtl .fc-toolbar-ltr {
  flex-direction: row-reverse;
}
.fc .fc-scroller {
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.fc .fc-scroller-liquid {
  height: 100%;
}
.fc .fc-scroller-liquid-absolute {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fc .fc-scroller-harness {
  direction: ltr;
  overflow: hidden;
  position: relative;
}
.fc .fc-scroller-harness-liquid {
  height: 100%;
}
.fc-direction-rtl .fc-scroller-harness > .fc-scroller {
  direction: rtl;
}
.fc-theme-standard .fc-scrollgrid {
  border: vars.$border-standard;
}
.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  table-layout: fixed;
  width: 100%;
}
.fc .fc-scrollgrid table {
  border-left-style: hidden;
  border-right-style: hidden;
  border-top-style: hidden;
}
.fc .fc-scrollgrid {
  border-bottom-width: 0;
  border-collapse: separate;
  border-right-width: 0;
}
.fc .fc-scrollgrid-liquid {
  height: 100%;
}
.fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section table,
.fc .fc-scrollgrid-section > td {
  height: 1px;
}
.fc .fc-scrollgrid-section-liquid > td {
  height: 100%;
}
.fc .fc-scrollgrid-section > * {
  border-left-width: 0;
  border-top-width: 0;
}
.fc .fc-scrollgrid-section-footer > *,
.fc .fc-scrollgrid-section-header > * {
  border-bottom-width: 0;
}
.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
}
.fc .fc-scrollgrid-section-sticky > * {
  background: vars.$color-gray-500;
  position: sticky;
  z-index: 3;
}
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 0;
}
.fc .fc-scrollgrid-section-footer.fc-scrollgrid-section-sticky > * {
  bottom: 0;
}
.fc .fc-scrollgrid-sticky-shim {
  height: 1px;
  margin-bottom: -1px;
}
.fc-sticky {
  position: sticky;
}
.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
}
.fc .fc-view-harness-active > .fc-view {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc .fc-bg-event,
.fc .fc-highlight,
.fc .fc-non-business {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.fc .fc-non-business {
  background: vars.$color-gray-400;
}
.fc .fc-bg-event {
  background: vars.$color-green-apple-500;
  opacity: var($fc-bg-event-opacity);
}
.fc .fc-bg-event .fc-event-title {
  font-size: 0.875rem;
  font-style: italic;
  margin: 0.5em;
}
.fc .fc-highlight {
  background: vars.$color-blue-200;
}
.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: vars.$color-gray-100;
}
a.fc-event,
a.fc-event:hover {
  text-decoration: none;
}
.fc-event.fc-event-draggable,
.fc-event[href] {
  cursor: pointer;
}
.fc-event .fc-event-main {
  position: relative;
  z-index: 2;
}
.fc-event-dragging:not(.fc-event-selected) {
  opacity: 0.75;
}
.fc-event-dragging.fc-event-selected {
  box-shadow: vars.$shadow-standard;
}
.fc-event .fc-event-resizer {
  display: none;
  position: absolute;
  z-index: 4;
}
.fc-event-selected .fc-event-resizer,
.fc-event:hover .fc-event-resizer {
  display: block;
}
.fc-event-selected .fc-event-resizer {
  background: vars.$color-gray-500;
  border-color: inherit;
  border-radius: calc(var($fc-event-resizer-dot-total-width) / 2);
  border-style: solid;
  border-width: var($fc-event-resizer-dot-border-width);
  height: var($fc-event-resizer-dot-total-width);
  width: var($fc-event-resizer-dot-total-width);
}
.fc-event-selected .fc-event-resizer:before {
  bottom: -20px;
  content: '\ ';
  left: -20px;
  position: absolute;
  right: -20px;
  top: -20px;
}
.fc-event-selected,
.fc-event:focus {
  box-shadow: vars.$shadow-standard;
}
.fc-event-selected:before,
.fc-event:focus:before {
  bottom: 0;
  content: '\ ';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.fc-event-selected:after,
.fc-event:focus:after {
  background: vars.$color-gray-600;
  bottom: -1px;
  content: '\ ';
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 1;
}
.fc-h-event {
  background-color: vars.$color-blue-primary;
  border: 1px solid vars.$color-blue-primary;
  display: block;
}
.fc-h-event .fc-event-main {
  color: vars.$color-white;
}
.fc-h-event .fc-event-main-frame {
  display: flex;
}
.fc-h-event .fc-event-time {
  max-width: 100%;
  overflow: hidden;
}
.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
.fc-h-event .fc-event-title {
  display: inline-block;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  right: 0;
  vertical-align: top;
}
.fc-h-event.fc-event-selected:before {
  bottom: -10px;
  top: -10px;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
  border-bottom-left-radius: 0;
  border-left-width: 0;
  border-top-left-radius: 0;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
}
.fc-h-event:not(.fc-event-selected) .fc-event-resizer {
  bottom: 0;
  top: 0;
  width: var($fc-event-resizer-thickness);
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
  cursor: w-resize;
  left: calc(var($fc-event-resizer-thickness) * -0.5);
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
  cursor: e-resize;
  right: calc(var($fc-event-resizer-thickness) * -0.5);
}
.fc-h-event.fc-event-selected .fc-event-resizer {
  margin-top: calc(var($fc-event-resizer-dot-total-width) * -0.5);
  top: 50%;
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-end {
  left: calc(var($fc-event-resizer-dot-total-width) * -0.5);
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-start {
  right: calc(var($fc-event-resizer-dot-total-width) * -0.5);
}
.fc .fc-popover {
  box-shadow: vars.$shadow-standard;
  position: absolute;
  z-index: 9999;
}
.fc .fc-popover-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 4px;
}
.fc .fc-popover-title {
  margin: 0 2px;
}
.fc .fc-popover-close {
  cursor: pointer;
  font-size: 1rem;
  opacity: 0.65;
}
.fc-theme-standard .fc-popover {
  background: vars.$color-gray-500;
  border: vars.$border-standard;
}
.fc-theme-standard .fc-popover-header {
  background: vars.$color-gray-400;
}
