@use 'src/styles/vars';

.imagen-rectangular {
  max-height: 266px;
  overflow: hidden;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  img {
    height: auto;
    width: 100%;
  }
}

@media screen and (min-width: (vars.$md + 1)) {
  .small-button {
    font-size: 0.75rem;

    .mat-button {
      border-radius: 0.25rem;
      line-height: 22px;
      min-width: 40px;
      padding: 0 5px;
    }
  }
}

@media screen and (min-width: (vars.$sm + 1)) and (max-width: vars.$md) {
  .small-button {
    font-size: 0.625rem;

    .mat-button {
      border-radius: 0.25rem;
      line-height: 22px;
      min-width: 40px;
      padding: 0 5px;
    }
  }
}

@media screen and (max-width: vars.$sm) {
  .small-button {
    font-size: 0.625rem;

    .mat-button {
      border-radius: 0.25rem;
      line-height: 22px;
      min-width: 40px;
      padding: 0 5px;
    }
  }
}

.fc-next-button,
.fc-prev-button {
  background-color: vars.$color-white !important;
  border: none !important;
  color: vars.$color-blue-primary !important;
  outline: none !important;
  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.fc-event-title {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-listWeek-button,
.fc-today-button {
  background-color: vars.$color-blue-primary !important;
  color: vars.$color-white !important;
  border-color: vars.$color-blue-primary !important;
}

.fc-button-active {
  background-color: vars.$color-blue-700 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  .fc-daygrid-day-top {
    color: vars.$color-blue-primary !important;
  }
}
