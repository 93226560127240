:root {
  --org-graph-width: 3295px;
  --org-graph-height: 2551px;
}

@media print {
  app-header.d-flex,
  .mat-tab-label-container,
  .title-container,
  .filter-container {
    display: none !important;
  }

  .graph-container {
    svg.ngx-charts {
      width: var(--org-graph-width);
      height: var(--org-graph-height);
    }
  }
  @page {
    margin: 0;
  }
}
