@use 'src/styles/vars';

.neo-sidebar {
  z-index: 501;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: (vars.$md + 1)) {
    max-width: 300px;
  }

  &.neo-sidebar-closed {
    .mdc-list-item__primary-text {
      display: flex;
    }
  }
}

.sidebar-menu-container {
  cursor: pointer;
  padding: 0 16px;
}

.mobile-sidebar-backdrop {
  z-index: 500;
}

.mobile-sidebar {
  z-index: 501;
}

.menu-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.list-group-item {
  height: 48px !important;
  border-radius: 2rem !important;
  --mdc-list-list-item-label-text-color: #{vars.$color-gray-600};
  --mdc-list-list-item-focus-label-text-color: #{vars.$color-gray-600};
  --mdc-list-list-item-hover-label-text-color: #{vars.$color-gray-600};
  --mdc-list-list-item-leading-icon-color: #{darken(vars.$color-gray-600, 5%)};
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-line-height: 1.25rem;

  &:hover:not(.router-link-active) {
    background: vars.$color-blue-100;
  }

  mat-icon {
    margin-right: 0 !important;
  }
}

.router-link-active {
  border: 1px solid vars.$color-blue-primary;
  color: vars.$color-blue-primary;
  --mdc-list-list-item-focus-label-text-color: #{vars.$color-blue-primary};
  --mdc-list-list-item-label-text-color: #{vars.$color-blue-primary};
  --mdc-list-list-item-leading-icon-color: #{vars.$color-blue-primary};
  --mdc-list-list-item-focus-state-layer-color: none;
  --mdc-list-list-item-hover-leading-icon-color: #{vars.$color-blue-primary};
  --mdc-list-list-item-hover-label-text-color: #{vars.$color-blue-primary};

  mat-icon {
    color: vars.$color-white;
  }

  .icon::before {
    display: inline-block !important;
  }
}
