app-fichaje-alta,
app-administrador-documentacion,
app-documentacion-admin-alta,
app-user-documentacion,
app-documentacion-user-alta,
app-documentacion-user-detalle,
app-user-ausencias,
app-ausencias-alta,
app-ausencias-gant,
app-administrador-eventos,
app-calendario-eventos,
app-categoria-documentacion,
app-categoria-documentacion-alta,
app-categoria-empleados,
app-categorias-empleados-alta,
app-categorias-entrega-alta,
app-categorias-tipo-de-entrega,
app-configuracion,
app-configuracion-rol-generacion,
app-configuracion-sistema,
app-configuracion-usuario,
app-dashboard-eventos,
app-dashboard-buzon,
app-dashboard-documents,
app-dashboard-ausencias,
app-dashboard-comunicados,
app-datos-contacto,
app-datos-personales,
app-datos-personales-agrupados,
app-detalle-empleado,
app-dias-por-antiguedad,
app-dias-por-antiguedad-alta,
app-empleados,
app-feriados,
app-informacion,
app-informacion-alta,
app-informacion-detalle,
app-informacion-detalle,
app-licencias,
app-licencias-alta,
app-colaboradores,
app-perfil,
app-perfil-empleado-shared,
app-perfil-empresa,
app-datos-empresa,
app-perfil-usuario,
app-roles,
app-tipo-sancion,
app-tipo-sancion,
app-tipo-sancion-alta,
app-tipo-feedback,
app-tipo-feedback-alta,
app-tipos-de-entrega,
app-tipos-de-entrega-alta,
app-user-eventos,
app-absence-request,
app-roles,
app-configuracion-rol-generacion,
app-configuracion-usuario,
app-perfil-usuario,
app-categoria-documentacion,
app-categoria-documentacion-alta,
app-feriados,
app-licencias-alta,
app-licencias,
app-configuracion-sistema,
app-tipos-de-entrega,
app-dias-por-antiguedad,
app-dias-por-antiguedad-alta,
app-tipo-sancion-alta,
app-tipos-de-entrega-alta,
app-categorias-tipo-de-entrega,
app-categorias-entrega-alta,
app-categoria-empleados,
app-categorias-empleados-alta,
app-perfil,
app-mi-cuenta,
app-perfil-empleado-shared,
app-nomina,
app-grafico-organigrama,
app-organigrama-por-cargos,
app-organigrama-por-area,
app-administrador-eventos,
app-calendario-ausencias,
app-dashboard-eventos,
app-user-eventos,
app-evento-alta,
app-user-entregas,
app-user-solicitudes-alta,
app-administrador-entregas,
app-administrador-ausencias,
app-listado-de-ausencias,
app-entregas-alta,
app-lista-comunicaciones,
app-user-comunicados,
app-bancos,
app-bancos-alta,
app-sanciones,
app-sancion-a-personal,
app-user-sanciones,
app-lista-sanciones,
app-feedback,
app-feedback-a-personal,
app-user-feedbacks,
app-calendario,
app-comunicados,
app-notificaciones,
app-areas,
app-alta-areas,
app-cargos,
app-alta-cargos,
app-alta-personal,
app-convenio-colectivo,
app-beneficios-adicionales,
app-alta-beneficios-adicionales,
app-alta-convenio-colectivo,
app-listado-de-entregas,
app-analitica-premium,
app-indicador-licencias,
app-indicador-segmentada,
app-indicadores-varios,
app-indice-rotacion-anual,
app-indicador-numero-promedio,
app-cambio-de-estado,
app-oficinas,
app-alta-oficinas,
app-selector-categorias-documentacion,
app-comunicados-alta,
app-alta-rapida-personal,
app-documentacion-admin-detalle,
app-organigrama-user,
app-elearning,
app-evaluaciones,
app-administrador-cuestionarios,
app-administrador-cuestionarios-listado,
app-user-cuestionarios,
app-user-cuestionarios-listado,
app-cuestionarios-listado-shared,
app-cuestionario-formulario,
app-cuestionarios,
app-documentacion-detalle-shared,
app-previsualizacion-documentos,
app-documentacion-alta-shared,
app-administrador-documentacion-alta-uno-a-muchos,
app-administrador-documentacion-listado-uno-a-muchos,
app-administrador-documentacion-detalle-uno-a-muchos,
app-documentacion-listado-shared,
app-dashboard-clock-in-out,
app-control-horario,
app-clock-in-out-remote,
app-clock-in-out-terminal,
app-documentacion-edicion-shared,
app-user-acontecimientos-alta,
app-detalle-sancion-full,
app-user-lista-sanciones-full,
app-detalle-feedback-full,
app-user-lista-feedbacks-full,
app-user-entregas,
app-user-entregas-shared,
app-capacitaciones-shared,
app-user-capacitaciones,
app-user-solicitudes-ausencia,
app-shared-user-ausencias,
app-administrador-cuestionarios-configuracion,
app-administrador-cuestionario-alta,
app-cuestionario-pregunta,
app-cuestionario-main,
app-resumen-firmas,
app-cuestionario-tipo-preguntas,
app-only-text-question,
app-nivel-de-estudio,
app-nivel-de-estudio-alta,
app-ultimos-feedbacks,
app-configuracion-feedbacks,
app-destinatarios,
app-administrador-planificacion-cuestionario,
app-administrador-formularios-listado,
app-formularios-listado,
app-administrador-formularios-ejecuciones,
app-ejecuciones-listado,
app-administrador-formularios-ejecuciones-cuestionarios,
app-user-tasks,
app-user-tasks-list,
app-communication-resend,
app-administrador-tasks,
app-clockin-comment,
app-user-task-detail,
app-task-item-detail,
app-task-goal-detail,
app-task-pending-list,
app-ofertas,
app-candidato-card,
app-detalles-oferta,
app-nuevo-candidato,
app-nuevo-candidato-talento,
app-nuevo-candidato-oferta,
app-nueva-oferta,
app-editar-oferta,
app-duplicar-oferta,
app-oferta-candidato-card,
app-ofertas-tab,
app-candidatos-tab,
app-recomendados-tab,
app-talentos-tab,
app-phase-form,
app-offers-list,
app-detalle-oferta-candidatos-data-tab,
app-file-list,
app-task-pending-list-by-user,
app-documentacion-por-tipo,
app-documentacion-shared,
app-document-list,
app-recommended-candidate-card,
app-footer-public,
app-email-validation-form,
app-oferta,
app-postulation-form,
app-competence-type-form,
app-competence-skill-form,
app-company-list,
app-competence-categor-form,
app-user-load-work-day,
app-signature-summary-list,
app-comment,
app-user-forms-statistics,
app-performance,
app-performance-skills,
app-fichaje-admin,
app-fichaje-user,
app-fichaje-control-horario,
app-performance-executions,
app-executions-list,
app-workday-selector,
app-workday-calendar,
app-performance-behaviors,
app-competence-category-list,
app-executions-list,
app-performance-evaluations,
app-evaluations-list,
app-fichaje-control-horario,
app-performance-executions,
app-executions-list,
app-workday-selector,
app-workday-calendar,
app-crud-goals-tab,
app-performance-goals,
app-absences-calendar,
app-company-absences,
app-workday-table,
app-candidates-list,
app-results-calibration,
app-request-list,
app-communications-list,
app-user-feed-page,
app-cambios-empleados,
app-new-rejection-reason {
  display: flex;
  flex: 1;
}

app-user-comunicados,
app-fichaje-admin,
app-fichaje-user,
app-alta-fichaje,
app-comentarios,
app-alta-categoria-capacitaciones,
app-categoria-capacitaciones,
app-capacitaciones,
app-fichaje-control-horario,
app-firmas,
app-user-comunicados,
app-comentarios,
app-user-capacitaciones,
app-user-solicitudes-capacitaciones-alta,
app-administrador-capacitaciones,
app-capacitaciones-alta,
app-listado-de-capacitaciones,
app-clock-in-out-terminal,
app-clock-in-out-remote {
  width: 100%;
}

app-clock-in-out-terminal,
app-clock-in-out-remote {
  height: 100%;
}

app-user,
app-clock-in-out-qr,
app-administrador,
app-backoffice,
app-backoffice-dashboard,
app-user-dashboard,
app-administrador-dashboard {
  align-items: stretch;
  display: flex;
  flex-grow: 1;
}
