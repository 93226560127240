@use 'src/styles/vars';

.group-buttons {
  display: inline-flex;

  button.mat-mdc-raised-button,
  button.mat-mdc-unelevated-button {
    border-right: 1px solid vars.$color-white;
  }

  button.mat-mdc-raised-button:last-child,
  button.mat-mdc-unelevated-button:last-child {
    border-left: 0;
  }
}

.edit-button {
  box-shadow: 0 0 1px 2px vars.$color-white !important;
  transform: translate(-24px) !important;
}

.button-back {
  color: vars.$color-gray-600 !important;
  background-color: vars.$color-gray-300 !important;
  box-shadow: none !important;
}

a.mat-mdc-mini-fab.mat-primary {
  color: vars.$color-blue-primary !important;
  background-color: transparent;
}

.btn-suffix-rounded {
  background-color: vars.$color-blue-primary;
  border-radius: 50%;
  color: vars.$color-white;
  width: 2em !important;
  height: 2em !important;

  .mat-button-wrapper {
    padding: 2.5px 0 !important;
  }

  .material-symbols-outlined {
    font-size: 1.25rem !important;
  }
}

.spinner-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  mat-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    circle {
      stroke: vars.$color-white !important;
    }
  }
}

.sm-icon {
  height: 1rem !important;
  width: 1rem !important;
  font-size: 1rem !important;
}
