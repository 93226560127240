@use 'src/styles/vars';

/*************************** Tablas expandibles *****************************/
table {
  tr.detail-row-expandable {
    height: 0;
  }

  .element-row-expandable td {
    border-bottom-width: 0;
  }

  .element-detail-expandable {
    display: flex;
    overflow: hidden;
  }
}

.table-striped {
  tr:nth-child(even) {
    background-color: vars.$color-gray-100;
  }

  tr:nth-child(odd) {
    background-color: vars.$color-white;
  }
}

/***************************************************************************/
.touchable-table {
  cursor: pointer;
}

/************************************************************************/

// Para simular looks de tabla en los virtual scroll
.box-bottom-table {
  border-bottom: vars.$border-standard;
}

.box-top-table {
  border-top: vars.$border-standard;
}

.box-table-encabezado {
  color: vars.$color-gray-600;
  font-size: 0.75rem !important;
  font-weight: 500;
  padding: 0.7rem !important;

  .text-center {
    .mat-sort-header-container {
      display: flex;
      justify-content: center;
    }
  }
}

.text-center {
  .mat-sort-header-container {
    justify-content: center;
  }
}

.virtual-row {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

.font-small {
  font-size: 0.875rem;
}

.comunicado-row {
  height: 50px;
}

.encabezado-row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
