@use 'src/styles/vars';
// TODO: ESTE ARCHIVO DEBERIA DESAPARECER Y LAS CLASES QUE SE USEN PASAR A COMPONENTES U A OTROS ARCHIVOS GLOBALES SEGUN TIPO

.mat-drawer-container {
  background-color: vars.$color-white;
}

.mat-standard-chip:hover::after {
  background: unset;
  opacity: unset !important;
}

.mat-figure {
  align-items: stretch !important;
  display: flex !important;
  flex: 1 !important;

  app-base-card {
    align-items: stretch;
    display: flex;
    flex: 1;
    position: relative;
  }
}

table {
  width: 100%;
}

.completer-mat {
  max-width: 270px;
  overflow: hidden;
  white-space: nowrap;
  width: 30%;
}

.error-card {
  color: vars.$color-white !important;
  padding: 10px;

  p {
    margin: 0;
    padding: 0;
  }
}

.chip-list-sm {
  .mat-standard-chip {
    font-size: 0.875rem;
    min-height: 24px;
    padding: 5px 8px;
  }
}

.form-sm {
  font-size: 0.875rem;

  .mat-mdc-raised-button {
    line-height: 28px;
    min-width: 45px;
    padding: 2px 5px;
  }

  .mat-mdc-unelevated-button {
    line-height: 28px;
    min-width: 45px;
    padding: 2px 5px;
  }
}

.pad-icon {
  padding-bottom: 10px;
}

.table-header {
  th {
    color: vars.$color-white;
  }
}

.tabla-ajustada {
  height: 550px;
  overflow: auto;
}

.upload-file-input {
  border: vars.$border-standard !important;
  padding-left: 6px !important;
}

.border-bottom {
  border-bottom: vars.$border-standard;
}

.stepper-100 {
  .mat-stepper-horizontal {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .mat-horizontal-content-container {
    flex-grow: 1;
  }

  .mat-horizontal-stepper-content {
    height: 100%;
  }

  .stepp-content-100 {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.all-items-selected {
  opacity: 0.75;
}

.flex-2 {
  flex: 0 0 16.666667%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 16.666667%;
  position: relative;
  width: 100%;
}

.pr-small {
  padding-right: 2px;
}

.vs-container {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.vs-viewport {
  flex-grow: 1;
}

.mat-tab-group.mat-tab-fill-height .mat-tab-body-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.material-border {
  border: vars.$border-standard;
  border-radius: 13.5px;
}

.main-footer {
  overflow: visible !important;
}

.scrollViewport {
  height: 300px;
}

neo-undo {
  z-index: 500;
}

.return-arrow {
  i {
    vertical-align: text-bottom;
  }
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  z-index: 1001;
  bottom: 0;
  background: vars.$color-gray-100;
}

.mobile-sidebar.mobile-sidebar-transition {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.mobile-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
}

.mobile-sidebar-backdrop.opacity-transition {
  transition: opacity 250ms ease-out;
}

.overflow-auto {
  touch-action: pan-y;
}

.navbar-toggler {
  border-color: transparent;
  outline: none !important;
}

.footer-button {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  padding-top: 0;
  border: none;
  background: none;
  outline: none !important;
}

.footer-border {
  border-top: vars.$border-standard;
}

.hours-outline .mat-form-field-outline-end {
  border-right: none !important;
  border-radius: 0 !important;
}

.minutes-outline .mat-form-field-outline-start {
  border-left: none !important;
  border-radius: 0 !important;
}

.sidebar-toggle {
  border-color: transparent;
  outline: none !important;
  margin-right: 10px;
  cursor: pointer;
}

ngx-mat-datetime-content {
  .mat-calendar-body-cell {
    position: relative !important;
  }
}

.bell-container {
  margin: -10px -10px -10px -5px;
  padding: 10px;
  border-radius: 50%;
}

.mat-toolbar-single-row.neo-header {
  height: 100%;
}

.pad-icon {
  padding-bottom: 8px;
  padding-left: 4px;
}

@media screen and (max-width: vars.$md) {
  .sidebar-toggler {
    display: none !important;
  }
}
@media screen and (min-width: (vars.$md + 1)) {
  .flex-lg-2 {
    flex: 0 0 16.666667%;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 16.666667%;
    position: relative;
    width: 100%;
  }

  .sidebar-toggler {
    display: block !important;
  }
}

@media screen and (max-width: vars.$sm) {
  .tab-container {
    padding: 0.75rem !important;
  }
}
